import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Layout from 'components/layout'
import Content from 'components/new/content'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'
import Ul from 'components/new/ul'
import Spacer from 'components/new/spacer'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const PrivacyPolicy = () => {
  return (
    <Layout kind='white'>
      <Helmet>
        <title>Privacy Policy | Hutson Inc</title>
      </Helmet>

      <Content kind='full'>
        <H2 as='h1'>Privacy Policy</H2>

        <P style={{ fontStyle: 'italic' }}>Last updated August 23, 2021</P>

        <P>
          Thank you for choosing to be part of our community at Hutson, Inc. ("Company", "we", "us",
          "our"). We are committed to protecting your personal information and your right to
          privacy. If you have any questions or concerns about this privacy notice, or our practices
          with regards to your personal information, please contact us at{' '}
          <a href='mailto:contactus@hutsoninc.com'>contactus@hutsoninc.com</a>.
        </P>

        <P>
          When you visit our website <Link to='/'>https://www.hutsoninc.com</Link> (the "Website"),
          and more generally, use any of our services (the "Services", which include the Website),
          we appreciate that you are trusting us with your personal information. We take your
          privacy very seriously. In this privacy notice, we seek to explain to you in the clearest
          way possible what information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it carefully, as it is
          important. If there are any terms in this privacy notice that you do not agree with,
          please discontinue use of our Services immediately.
        </P>

        <P>
          This privacy notice applies to all information collected through our Services (which, as
          described above, includes our Website), as well as, any related services, sales, marketing
          or events.
        </P>

        <P>
          <strong>
            Please read this privacy notice carefully as it will help you understand what we do with
            the information that we collect.
          </strong>
        </P>

        <Spacer size='m' />

        <H3 as='h2'>What information do we collect?</H3>

        <Spacer size='s' />

        <H4 as='h3'>Information automatically collected</H4>

        <P>
          We automatically collect certain information when you visit, use or navigate the Website.
          This information does not reveal your specific identity (like your name or contact
          information) but may include device and usage information, such as your IP address,
          browser and device characteristics, operating system, language preferences, referring
          URLs, device name, country, location, information about how and when you use our Website
          and other technical information. This information is primarily needed to maintain the
          security and operation of our Website, and for our internal analytics and reporting
          purposes.
        </P>

        <P>
          Like many businesses, we also collect information through cookies and similar
          technologies.
        </P>

        <Spacer size='s' />

        <H4 as='h3'>Information collected from other sources</H4>

        <P>
          In order to enhance our ability to provide relevant marketing, offers and services to you
          and update our records, we may obtain information about you from other sources, such as
          public databases, joint marketing partners, affiliate programs, data providers, as well as
          from other third parties. This information includes mailing addresses, job titles, email
          addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP)
          addresses, social media profiles, social media URLs and custom profiles, for purposes of
          targeted advertising and event promotion.
        </P>

        <Spacer size='m' />

        <H3 as='h2'>Will your information be shared with anyone?</H3>

        <P>We may process or share your data that we hold based on the following legal basis:</P>

        <Ul>
          <li>
            <strong>Consent.</strong> We may process your data if you have given us specific consent
            to use your personal information for a specific purpose.
          </li>
          <li>
            <strong>Legitimate Interests.</strong> We may process your data when it is reasonably
            necessary to achieve our legitimate business interests.
          </li>
          <li>
            <strong>Performance of a Contract.</strong> Where we have entered into a contract with
            you, we may process your personal information to fulfill the terms of our contract.
          </li>
          <li>
            <strong>Legal Obligations.</strong> We may disclose your information where we are
            legally required to do so in order to comply with applicable law, governmental requests,
            a judicial proceeding, court order, or legal process, such as in response to a court
            order or a subpoena (including in response to public authorities to meet national
            security or law enforcement requirements).
          </li>
          <li>
            <strong>Vital Interests.</strong> We may disclose your information where we believe it
            is necessary to investigate, prevent, or take action regarding potential violations of
            our policies, suspected fraud, situations involving potential threats to the safety of
            any person and illegal activities, or as evidence in litigation in which we are
            involved.
          </li>
        </Ul>

        <P>
          More specifically, we may need to process your data or share your personal information in
          the following situations:
        </P>

        <Ul>
          <li>
            <strong>Business Transfers.</strong> We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to another company.
          </li>
          <li>
            <strong>Affiliates.</strong> We may share your information with our affiliates, in which
            case we will require those affiliates to honor this privacy notice. Affiliates include
            our parent company and any subsidiaries, joint venture partners or other companies that
            we control or that are under common control with us.
          </li>
          <li>
            <strong>Business Partners.</strong> We may share your information with our business
            partners to offer you certain products, services or promotions.
          </li>
        </Ul>

        <Spacer size='m' />

        <H3 as='h2'>Do we use cookies and other tracking technologies?</H3>

        <P>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to
          access or store information. Specific information about how we use such technologies and
          how you can refuse certain cookies is set out in our Cookie Notice.
        </P>

        <Spacer size='m' />

        <H3 as='h2'>Is your information transferred internationally?</H3>

        <P>
          Our servers are located worldwide. Please be aware that your information may be
          transferred to, stored, and processed by us in our facilities and by those third parties
          with whom we may share your personal information, in and other countries.
        </P>

        <P>
          If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then
          these countries may not necessarily have data protection laws or other similar laws as
          comprehensive as those in your country. We will however take all necessary measures to
          protect your personal information in accordance with this privacy notice and applicable
          law.
        </P>

        <Spacer size='m' />

        <H3 as='h2'>How long do we keep your information?</H3>

        <P>
          We will only keep your personal information for as long as it is necessary for the
          purposes set out in this privacy notice, unless a longer retention period is required or
          permitted by law (such as tax, accounting or other legal requirements). No purpose in this
          notice will require us keeping your personal information for longer than 2 years.
        </P>

        <P>
          When we have no ongoing legitimate business need to process your personal information, we
          will either delete or anonymize such information, or, if this is not possible (for
          example, because your personal information has been stored in backup archives), then we
          will securely store your personal information and isolate it from any further processing
          until deletion is possible.
        </P>

        <Spacer size='m' />

        <H3 as='h2'>How do we keep your information safe?</H3>

        <P>
          We have implemented appropriate technical and organizational security measures designed to
          protect the security of any personal information we process. However, despite our
          safeguards and efforts to secure your information, no electronic transmission over the
          Internet or information storage technology can be guaranteed to be 100% secure, so we
          cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
          parties will not be able to defeat our security, and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your personal
          information, transmission of personal information to and from our Website is at your own
          risk. You should only access the Website within a secure environment.
        </P>

        <Spacer size='m' />

        <H3 as='h2'>Do we collect information from minors?</H3>

        <P>
          We do not knowingly solicit data from or market to children under 18 years of age. By
          using the Website, you represent that you are at least 18 or that you are the parent or
          guardian of such a minor and consent to such minor dependent's use of the Website. If we
          learn that personal information from users less than 18 years of age has been collected,
          we will deactivate the account and take reasonable measures to promptly delete such data
          from our records. If you become aware of any data we may have collected from children
          under age 18, please contact us at{' '}
          <a href='mailto:contactus@hutsoninc.com'>contactus@hutsoninc.com</a>.
        </P>

        <Spacer size='m' />

        <H3 as='h2'>What are your privacy rights?</H3>

        <P>
          If you are a resident in the EEA or UK and you believe we are unlawfully processing your
          personal information, you also have the right to complain to your local data protection
          supervisory authority. You can find their contact details here:{' '}
          <OutboundLink href='http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </OutboundLink>
          .
        </P>

        <P>
          If you are a resident in Switzerland, the contact details for the data protection
          authorities are available here:{' '}
          <OutboundLink href='https://www.edoeb.admin.ch/edoeb/en/home.html'>
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </OutboundLink>
          .
        </P>

        <P>
          Cookies and similar technologies: Most Web browsers are set to accept cookies by default.
          If you prefer, you can usually choose to set your browser to remove cookies and to reject
          cookies. If you choose to remove cookies or reject cookies, this could affect certain
          features or services of our Website.
        </P>

        <Spacer size='m' />

        <H3 as='h2'>Controls for Do-Not-Track features</H3>

        <P>
          Most web browsers and some mobile operating systems and mobile applications include a
          Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference
          not to have data about your online browsing activities monitored and collected. At this
          stage no uniform technology standard for recognizing and implementing DNT signals has been
          finalized. As such, we do not currently respond to DNT browser signals or any other
          mechanism that automatically communicates your choice not to be tracked online. If a
          standard for online tracking is adopted that we must follow in the future, we will inform
          you about that practice in a revised version of this privacy notice.
        </P>

        <Spacer size='m' />

        <H3 as='h2'>Do California residents have specific privacy rights?</H3>

        <P>
          California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits
          our users who are California residents to request and obtain from us, once a year and free
          of charge, information about categories of personal information (if any) we disclosed to
          third parties for direct marketing purposes and the names and addresses of all third
          parties with which we shared personal information in the immediately preceding calendar
          year. If you are a California resident and would like to make such a request, please
          submit your request in writing to us using the contact information provided below.
        </P>

        <P>
          If you are under 18 years of age, reside in California, and have a registered account with
          the Website, you have the right to request removal of unwanted data that you publicly post
          on the Website. To request removal of such data, please contact us using the contact
          information provided below, and include the email address associated with your account and
          a statement that you reside in California. We will make sure the data is not publicly
          displayed on the Website, but please be aware that the data may not be completely or
          comprehensively removed from all our systems (e.g. backups, etc.).
        </P>

        <Spacer size='m' />

        <H3 as='h2'>Do we make updates to this notice?</H3>

        <P>
          We may update this privacy notice from time to time. The updated version will be indicated
          by an updated "Revised" date and the updated version will be effective as soon as it is
          accessible. If we make material changes to this privacy notice, we may notify you either
          by prominently posting a notice of such changes or by directly sending you a notification.
          We encourage you to review this privacy notice frequently to be informed of how we are
          protecting your information.
        </P>

        <Spacer size='m' />

        <H3 as='h2'>How can you contact us about this notice?</H3>

        <P>
          If you have questions or comments about this notice, you may email us at{' '}
          <a href='mailto:contactus@hutsoninc.com'>contactus@hutsoninc.com</a> or by post to:
        </P>

        <P>
          Hutson, Inc.
          <br />
          306 Andrus Drive
          <br />
          Murray, KY 42071
          <br />
          United States
        </P>
      </Content>
    </Layout>
  )
}

export default PrivacyPolicy
